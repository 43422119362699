/* Basic Elements */

:root {
    --agreements: #4cbcd4;
    --customers: #4cd472;
    --vendors: #a25dae;
    --items: #657cca;
    --contracts: #cd548a;
    --gpos: #83b6b3;
    --settings: #445056;
    --base: #f4f6f7;
    --darkblue: #263238;
    --lightgray: #eaeef0;
    --mediumgray: #6c777b;
    --pointblue: #03a9f4;
    --textlight: #888e91;
    --textcolor: #000;
    --btnbase: #cbddef;
    --btnnext: #009a3e;
    --btn: #bcc9cf;
}

body {
    font-family: sans-serif;
    font-size: 14px;
    font-weight: lighter;
    background-color: var(--base);
}

body,
p,
ul,
h2 {
    margin: 0;
    padding: 0;
}

h3 {
    margin: 4px 0 16px 0;
    font-size: 14px;
}

a {
    text-decoration: none;
}

li {
    text-align: center;
    list-style: none;
}

h2 {
    color: var(--darkblue);
    font-size: 28px;
}

span {
    color: #69767b;
}

/* Input, Textarea, Button styling */

button {
    border-radius: 4px;
    border: none;
    background-repeat: no-repeat;
}

input,
textarea,
button,
div {
    font-size: 14px;
    outline: none;
    font-family: sans-serif;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: var(--textlight);
    font-weight: lighter;
    -webkit-text-fill-color: initial;
    font-size: 12px;
}

input {
    padding: 12px 8px;
    border: none;
    border-radius: 4px;
}

input::-webkit-inner-spin-button,
input:focus::-webkit-input-placeholder {
    display: none;
    -webkit-appearance: none;
}

/* Custom Select */

select {
    background-color: white;
    border: none;
    height: 40px;
    color: var(--darkblue);
    border-radius: 4px;
    -webkit-appearance: none;
    font-size: 14px;
    padding: 0 8px;
    outline: none;
    cursor: pointer;
}

select.gray {
    background-color: var(--base)
}

.selector {
    padding: 0 !important;
    margin: 0 !important;
    position: relative;
}

.selector select {
    width: 100%;
}

.selector::after {
    content: url('../images/icon_arrow_down.svg');
    width: 20px;
    height: 20px;
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
}

.infoBody select {
    width: 100%;
    font-size: 14px;
    background-color: var(--base);
}

/* Scroll */

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background: var(--textlight);
    border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
    background: #78909c;
}

/* Layout */

.topBar,
.listPage,
.contentDetail {
    margin-left: 91px;
}

.contentDetail {
    box-sizing: border-box;
}

.listPage,
.contentDetail {
    padding: 80px 60px;
}

.contentDetail {
    display: flex;
    justify-content: center;
    flex-flow: wrap;
}

.headerDetail {
    flex-basis: 100%;
}

.infoBody,
.listBody {
    border-radius: 4px;
    margin: 0 28px 0 0;
    flex: 1;
    height: 100%;
}

.listBodyHeader {
    display: flex;
    justify-content: space-between;
}

.listBodyHeader .tabOnDetail {
    flex-grow: 1;
}

.infoBody {
    background-color: white;
}

.listDetail {
    flex: 2;
    /* max-height:720px;
    overflow-x:hidden;
    overflow-y:auto; */
}

.topBar,
.listPage .headerSticky,
.tabLists .innerBackground,
.tabLists .headerTab {
    width: -webkit- calc(100% - 211px);
    width: -moz- calc(100% - 211px);
    width: calc(100% - 211px);
}

.btnArea {
    position: relative;
    margin: 28px 0 0 0;
    text-align: center;
}

.btnArea.loading::after {
    top: 10px;
    right: -webkit- calc(25% + 12px);
    right: -moz- calc(25% + 12px);
    right: calc(25% + 12px);
}

/* Navigation */

.nav {
    background-color: white;
    height: 100%;
    text-align: center;
    position: fixed;
    width: 90px;
    z-index: 100;
}

.nav ul {
    height: -webkit- calc(100% - 184px);
    height: -moz- calc(100% - 184px);
    height: calc(100% - 184px);
    overflow: hidden;
}

.nav ul:hover {
    overflow-y: auto;
}

.nav ul::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0);
}

.nav li,
.nav .createspa {
    height: 104px;
    background-position-x: center;
    background-position-y: 30px;
    background-repeat: no-repeat;
    background-size: 40px;
}

.nav li:hover,
.nav .createspa:hover {
    background-size: 54px;
}

.nav li p,
.nav .createspa p {
    padding-top: 76px;
    font-size: 12px;
    font-weight: bolder;
    color: var(--textcolor);
}

.nav .active p {
    color: white;
}

.nav .companyLogo {
    background-image: url("../images/logo_dealmed_text.png");
    background-position: center;
    background-repeat: no-repeat;
    height: 80px;
    background-color: rgb(231, 231, 233);
    background-size: 85%;
    vertical-align: middle;
}

.nav .companyLogo span {
    content: 'Alpha 0.0.1';
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    color: var(--agreements);
    font-weight: bolder;
    width: 100%;
}

@media (max-width:768px) {
    .minWidthSm {
        display: none
    }
}

@media (max-width:768px) {
    .nav .companyLogo span {
        top: 45px;
    }
}

@media (min-width:500px) {
    .menu-label {
        display: none;
    }
}

.nav .btnMenu {
    display: none;
}

.nav .createspa {
    background-image: url("../images/icon_nav_create.svg");
    width: 100%;
}

.nav .agreements {
    background-image: url("../images/icon_nav_agreements.png");
    width: 100%;
}

.nav .customers {
    background-image: url("../images/icon_nav_customers.png");
    width: 100%;
}

.nav .vendors {
    background-image: url("../images/icon_nav_vendors.png");
    width: 100%;
}

.nav .items {
    background-image: url("../images/icon_nav_items.png");
    width: 100%;
}

.nav .contracts {
    background-image: url("../images/icon_nav_contracts.png");
    width: 100%;
}

.nav .settings {
    background-image: url("../images/icon_nav_settings.png");
    width: 100%;
}

.nav .gpos {
    background-image: url("../images/icon_nav_gpos.png");
    width: 100%;
}

.nav .menu-hover:hover {
    background-color: rgb(209, 209, 209);
}

.nav .active .menu-hover {
    background-color: rgb(88, 88, 88);
}

/* Top Bar */

.topBar {
    background-color: white;
    z-index: 1000;
    position: fixed;
    padding: 15px 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.topBar .profile {
    background-repeat: no-repeat;
    height: 40px;
    padding-left: 48px;
    margin-left: 16px;
}

.topBar .accountName {
    color: var(--darkblue);
    font-size: 16px;
    font-weight: bolder;
    margin-top: 5px;
    white-space: nowrap;
}

.topBar .role {
    font-size: 10px;
    color: var(--base);
    padding: 2px 8px;
    border-radius: 4px;
}

.sales {
    background-color: #4fb1bc;
    white-space: nowrap;
}

.btnNet {
    width: 40px;
    height: 40px;
    background-size: 24px;
    padding: 0;
    margin: 0 0 0 16px;
    background-position: center;
    background-color: var(--base);
    cursor: pointer;
    background-image: url("../images/logo_netsuite.svg");
}

/* Search */

.globalSearch input,
.commonSearch input,
.itemSearch input {
    background-color: var(--base);
    border-style: none;
    border-bottom: 2px solid var(--lightgray);
    padding: 0 12px;
    height: 48px;
    vertical-align: top;
    width: -webkit- calc(100% - 24px);
    width: -moz- calc(100% - 24px);
    width: calc(100% - 24px);
    font-size: 18px;
    border-radius: 0;
}

.globalSearch input:focus,
.globalSearch .inputted input:focus,
.commonSearch input:focus,
.commonSearch .inputted input:focus,
.itemSearch input:focus,
.itemSearch .inputted input:focus {
    border: none;
    border-bottom: 2px solid var(--pointblue);
    color: var(--pointblue);
    font-size: 24px;
    background-color: rgba(255, 255, 255, 0);
}

.globalSearch .inputted input,
.commonSearch .inputted input,
.itemSearch .inputted input {
    border-bottom: 2px solid var(--darkblue);
    color: var(--darkblue);
    font-size: 20px;
    -webkit-transition: .2s ease-in;
    -moz-transition: .2s ease-in;
    -o-transition: .2s ease-in;
    transition: .2s ease-in;
}

.itemSearch div {
    display: inline-block;
}

.itemSearch div input {
    width: -webkit- calc(100% - 32px);
    width: -moz- calc(100% - 32px);
    width: calc(100% - 32px);
}

.itemSearch div:last-child input {
    width: -webkit- calc(100% - 24px);
    width: -moz- calc(100% - 24px);
    width: calc(100% - 24px);
}

.contentDetail .commonSearch input,
.contentDetail .itemSearch input {
    height: 36px;
    margin-bottom: 8px;
}

.listPage .headerArea form {
    margin: 12px 0;
}

/* Global Search */

.globalSearch {
    position: relative;
    width: 100%;
}

.globalSearch::after {
    content: "";
    background-image: url('../images/icon_magnifier.svg');
    position: absolute;
    right: 12px;
    top: 14px;
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
}

.globalSearch.loading::after {
    background-image: url('../images/icon_pending.svg');
    background-size: 28px;
    top: 11px;
    right: 9px;
    animation: spin 1.5s ease-out infinite;
}

.globalSearch form input:focus~.globalSearch form::after {
    background-color: red;
}

.globalSearch form input {
    background-color: white;
    padding: 0 40px 0 12px;
    width: -webkit- calc(100% - 52px);
    width: -moz- calc(100% - 52px);
    width: calc(100% - 52px);
}

.loading::after {
    background-image: url('../images/icon_pending.svg');
    content: '';
    width: 28px;
    height: 28px;
    background-size: 28px;
    position: absolute;
    animation: spin 1.5s ease-out infinite;
    right: 4px;
    top: 6px;
}

.searchLoading {
    position: relative;
}

.searchLoading.loading::after {
    top: 10px;
}

/* New Result by Search*/

.resultBySearch {
    position: absolute;
    margin-top: 16px;
    width: 100%;
    background-color: white;
    z-index: 1;
    overflow-x: hidden;
    max-height: 304px;
    left: 0;
    border-radius: 4px;
    box-shadow: 0 0 12px 0 rgba(62, 108, 132, 0.2);
}

.resultBySearch ul li:first-child {
    border-top: none;
}

.resultBySearch ul li {
    font-size: 14px;
    color: #444a56;
    padding: 22px 22px;
    border-top: 1px solid var(--lightgray);
}

/* E-Mail & Phone*/

.mailtoList {
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--mediumgray);
    text-decoration: underline;
}

.mailtoList:hover {
    color: var(--base);
}

.mailtoDetail {
    width: calc(100% - 20px);
}

.mailtoDetail::after {
    content: '';
    background-color: var(--agreements);
    -webkit-mask-image: url('../images/icon_mail.svg');
    mask-image: url('../images/icon_mail.svg');
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
    position: absolute;
    margin-left: 4px;
}

.phoneDetail::after {
    content: '';
    background-color: var(--agreements);
    -webkit-mask-image: url('../images/icon_phone.svg');
    mask-image: url('../images/icon_phone.svg');
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
    position: absolute;
    margin-left: 4px;
}

/* Price */

.price::before {
    content: '$';
    position: absolute;
    top: 50%;
    left: 18px;
    color: var(--textlight);
    font-size: 12px;
    transform: translateY(-50%);
}

.price input {
    text-align: right;
    padding: 12px 8px 12px 20px;
    width: -webkit- calc(100% - 12px) !important;
    width: -moz- calc(100% - 12px) !important;
    width: calc(100% - 12px) !important;
    box-sizing: border-box;
}

@media (max-width:768px) {
    .price input {
        width: 100% !important;
    }
}

/* Common Status */

select option.defaultOption {
    color: var(--mediumgray) !important;
}

/* Common Message */

.alertNoVendor {
    color: var(--base);
    font-size: 12px;
    background-color: var(--contracts) !important;
    text-align: center;
    position: absolute;
    top: 0px;
    left: 0;
    padding: 12px 0;
    width: 100%;
    border-radius: 4px;
}

.alertNoVendor p::before {
    background-image: url("../images/icon_noresult.svg");
    background-size: 10px;
    background-repeat: no-repeat;
    content: '';
    width: 12px;
    height: 12px;
    display: inline-block;
    margin-right: 4px;
    vertical-align: bottom;
}

.errorMsg {
    text-align: center;
    margin: 0 auto;
    color: var(--mediumgray);
    background-image: url("../images/icon_noresult.svg");
    background-repeat: no-repeat;
    background-position: center 4px;
    background-size: 68px;
    width: 200px;
    height: 100px;
    font-size: 12px;
}

.errorMsg::after {
    content: 'No Result';
    position: relative;
    top: 78px;
}

.errorMsg.alertNoItem::after {
    content: 'Please Select An Item';
}

.errorMsg.alertNoCustomer::after {
    content: 'Please Select A Customer'
}

.errorMsg p {
    padding-top: 60px;
}

.error input,
.error>select,
.error .selectCalendar {
    background-color: #fff8f7 !important;
}

.error p {
    color: #ff6256 !important;
}

.error p::before {
    content: "";
    display: inline-block;
    width: 13px;
    height: 13px;
    background-image: url('../images/icon_problem.svg');
    background-size: 12px;
    background-repeat: no-repeat;
    margin-right: 3px;
    vertical-align: bottom;
}

.enterPriceError {
    font-size: 15px !important;
    position: absolute;
}


.overDiscount::before {
    content: "";
    position: absolute;
    width: 20px;
    height: 100%;
    left: 4px;
    background-image: url('../images/icon_problem.svg');
    background-size: 20px;
    background-position: center;
    background-repeat: no-repeat;
}

.modalContent>.alertMsg {
    width: -webkit- calc(100% - 40px);
    width: -moz- calc(100% - 40px);
    width: calc(100% - 40px);
    margin: 8px auto;
}

.alertMsg {
    background-color: #f3fff6;
    opacity: 1;
    padding: 16px 20px;
    text-align: center;
    border-radius: 4px;
    width: 100%;
    margin: 8px 0;
    box-sizing: border-box;
}

.alertMsg span {
    vertical-align: middle;
    color: var(--mediumgray);
    font-weight: bolder;
}

.alertMsg button {
    float: right;
    background-color: rgba(0, 0, 0, 0);
    background-image: url("../images/icon_btn_remove.svg");
    background-repeat: no-repeat;
    background-size: 12px;
    width: 20px;
    height: 20px;
    margin: 0;
    padding: 0;
    background-position: center;
}

.dangerMsg {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    opacity: 1;
    padding: 16px 20px;
    text-align: center;
    border-radius: 4px;
    width: 100%;
    margin: 8px 0;
    box-sizing: border-box;
}

.hoverable {
    cursor:pointer;
    margin: 10px 10px;
}

.marginLeft{
    margin-left: 30px;
}
/* Form */

.selected input {
    background-color: #f3fff6 !important;
    color: var(--darkblue) !important;
}

.radioComponent {
    display: flex;
}

.selectSource {
    border-top: 1px dashed var(--lightgray);
    padding-top: 12px;
    margin-top: 12px;
}

.radioComponent input[type="radio"] {
    width: 20px;
}

.radioComponent label {
    margin: 0 20px 0 4px;
    font-size: 14px;
    color: var(--darkblue);
}

/* List Style */

.noteDetail {
    margin: 40px 0 0 0;
    flex-basis: 100%;
}

.noteDetail textarea {
    width: -webkit- calc(100% - 40px);
    width: -moz- calc(100% - 40px);
    width: calc(100% - 40px);
    min-height: 80px;
    padding: 20px;
    resize: none;
    border-radius: 4px;
    border: none;
}

.noteDetail textarea::-webkit-input-placeholder {
    font-size: 14px;
}

.scroll-200 {
    max-height: 200px;
    overflow-y: scroll;
}

/* Detail Page */

.infoDetail {
    margin: 28px 28px 0 0;
    width: 36%;
    max-width: 360px;
    vertical-align: top;
    float: left;
}

.headerDetail {
    height: 56px;
    margin: 28px 0 20px 0;
    display: flex;
}

.headerDetail>button:first-child {
    margin: 0 16px 0 0;
}

.headerDetail>button,
.headerDetail>button:last-child,
.headerDetail>a>button:last-child {
    margin: 0 0 0 8px;
}

.titleDetail {
    border-radius: 4px;
    background-color: var(--darkblue);
    height: 100%;
    flex: 1;
}

.titleDetail.agreements {
    display: flex;
    color: white;
    align-items: center;
}

.titleDetail.agreements .agreement-name {
    padding: initial 16px;
    font-size: 16px;
    padding-left: 5px;
}

.titleDetail.agreements .agreement-owner-full-name {
    margin-left: auto;
    padding: initial 20px;
    font-size: 16px;
    padding-right: 10px;
}

.titleDetail.agreements .agreement-owner-full-name form {
    margin-top: 5px !important;
}

.titleDetail.agreements .agreement-owner-full-name.editable {
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    transition-duration: 0s;
}

.titleDetail.agreements .agreement-owner-full-name.editable:after {
    content: url("../images/icon_edit_white.png");
    padding-left: 20px;
}

.titleDetail.agreements .agreement-owner-full-name.editable:hover {
    color: #81e9ff;
}


.titleDetail.agreements .agreement-owner-full-name.editable:hover:after {
    content: url("../images/icon_edit_blue.png");
    padding-left: 20px;
}

.titleDetail.SPA .infoIcon {
    background-image: url("../images/icon_nav_create.svg");
    background-color: var(--pointblue);
}

.titleDetail.agreements .infoIcon {
    background-image: url("../images/icon_nav_agreements.png");
    background-color: var(--agreements);
}

.titleDetail.customers .infoIcon {
    background-image: url("../images/icon_nav_customers.png");
    background-color: var(--customers);
}

.titleDetail.vendors .infoIcon {
    background-image: url("../images/icon_nav_vendors.png");
    background-color: var(--vendors);
}

.titleDetail.items .infoIcon {
    background-image: url("../images/icon_nav_items.png");
    background-color: var(--items);
}

.titleDetail.contracts .infoIcon {
    background-image: url("../images/icon_nav_contracts.png");
    background-color: var(--contracts);
}

.titleDetail.gpos .infoIcon {
    background-image: url("../images/icon_nav_gpos.png");
    background-color: var(--gpos);
}

.titleDetail.settings .infoIcon {
    background-image: url("../images/icon_nav_settings.png");
    background-color: var(--settings);
}

.titleDetail h2 {
    display: inline-block;
    vertical-align: middle;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 18px;
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    width: calc(100% - 80px);
    height: 21px;
}

.headerDetail .SPA h2 {
    color: var(--pointblue);
}

.headerDetail .agreements h2 {
    color: var(--agreements);
}

.headerDetail .customers h2 {
    color: var(--customers);
}

.headerDetail .vendors h2 {
    color: var(--vendors);
}

.headerDetail .items h2 {
    color: var(--items);
}

.headerDetail .contracts h2 {
    color: var(--contracts);
}

.headerDetail .gpos h2 {
    color: var(--gpos);
}

.headerDetail .settings h2 {
    color: var(--mediumgray);
}

.titleDetail.SPA .infoIcon,
.titleDetail.agreements .infoIcon,
.titleDetail.customers .infoIcon,
.titleDetail.vendors .infoIcon,
.titleDetail.items .infoIcon,
.titleDetail.contracts .infoIcon,
.titleDetail.gpos .infoIcon,
.titleDetail.settings .infoIcon {
    background-size: 36px 36px;
    background-repeat: no-repeat;
    background-position: center;
    width: 56px;
    height: 56px;
    border-radius: 4px 0 0 4px;
    display: inline-block;
    vertical-align: middle;
}

.infoBody ul li {
    border-top: 1px solid var(--lightgray);
    padding: 20px 28px;
    text-align: left;
}

.infoBody input,
.basicList li input,
.basicPanel input {
    background-color: var(--base);
    border-radius: 4px;
    border: none;
    color: var(--darkblue);
    width: -webkit- calc(100% - 16px);
    width: -moz- calc(100% - 16px);
    width: calc(100% - 16px);
    /* box-sizing: border-box; */
}

.infoBody ul li:first-child {
    border-top: none;
}

.infoBody ul li p,
.basicPanel p {
    font-size: 12px;
    font-weight: bolder;
    color: var(--textcolor);
    margin-bottom: 8px;
}

.infoBody ul li span {
    font-size: 14px;
    color: var(--darkblue);
}

.listDetail.gpos {
    float: left;
    margin: 0 28px 28px 0;
    width: -webkit- calc(50% - 14px);
    width: -moz- calc(50% - 14px);
    width: calc(50% - 14px);
}

.listDetail.tiers {
    margin: 0;
}

.listDetail::after {
    clear: both;
}

.listDetail .basicList {
    max-height: 590px;
    overflow-x: hidden;
    overflow-y: auto;
}

.listDetail .basicList,
.singlePanel .basicList {
    margin-top: 8px;
}

.listDetail .basicList ul li {
    cursor: pointer;
}

.tabOnDetail {
    background-color: var(--lightgray);
    border-radius: 4px;
    height: 52px;
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    font-size: 14px;
    color: #263238;
    align-items: center;
}

.tabOnDetail .tab-link {
    font-size: 14px;
    margin: 0 auto;
    color: var(--textblack);
    flex-grow: 1;
    vertical-align: middle;
    align-items: center;
    display: grid;
    width: 100%;
    text-align: center;
    height: 100%;
}

.tabOnDetail .tab-link-active {
    background-color: var(--darkblue);
    border-radius: 4px;
    color: var(--base);
}

.buttons {
    width: 100%;
    text-align: center;
    margin-top: 40px;
}

.buttons button {
    width: 40%;
    margin: 0 12px;
}

/* Progress */

.progress {
    text-align: center;
    margin: 20px 0 20px 0;
    display: flex;
    justify-content: center;
    flex-flow: nowrap;
    position: relative;
}

.progress::after {
    position: absolute;
    height: 4px;
    content: '';
    width: 232px;
    bottom: 24px;
    background-color: var(--lightgray);
}

.progress.update::after {
    width: 50%;
}

.progress button {
    background-color: var(--darkblue);
    color: var(--base);
    width: 36px;
    height: 36px;
    text-align: center;
    font-size: 14px;
    margin-bottom: 8px;
    border-radius: 18px;
    border: none;
}

.progress button.selected {
    background-color: var(--agreements);
}

.step-2.selected::after,
.step-3.selected::after {
    position: absolute;
    height: 4px;
    content: '';
    left: -55%;
    width: 112px;
    bottom: 24px;
    background-color: var(--darkblue);
    z-index: -1
}

.step-3.selected::after {
    width: 232px;
    left: -204%;
}

.progress div {
    margin-right: 20px;
    position: relative;
    z-index: 10;
}

.progress div:last-child {
    margin-right: 0;
    z-index: 9;
}

.progress p {
    background-color: white;
    padding: 12px 24px;
    border-radius: 4px;
    font-size: 12px;
    color: var(--darkblue);
    position: relative;
    margin-bottom: 16px;
}

.progress p::after {
    content: '';
    width: 8px;
    height: 8px;
    background-color: white;
    position: absolute;
    margin-left: -4px;
    left: 50%;
    bottom: -4px;
    transform: rotate(45deg);
}

/* Error Page */

.errorIcon {
    background-image: url('../images/icon_error.svg');
    background-position: top;
    background-size: 200px;
    background-repeat: no-repeat;
    width: 100%;
    margin: 80px 0 80px 0;
    text-align: center;
    padding: 220px 0 0 0;
}

.errorIcon p {
    font-size: 20px;
    margin: 8px 0 24px 0;
}

/* Setting */

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .2s;
    transition: .2s;
    border-radius: 50%;
}

.switchAA:checked+.slider {
    background-color: var(--pointblue);
}

.switchAA:focus+.slider {
    box-shadow: 0 0 1px var(--darkblue);
}

.switchAA:checked+.slider:before {
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px);
}

/* CSV Uploader */

.csvReader {
    width: 56px;
    height: 56px;
    margin-left: 8px;
    background-image: url("../images/icon_btn_upload.svg");
    background-repeat: no-repeat;
    background-size: 26px;
    background-position: center;
    background-color: var(--textlight);
    border-radius: 4px;
}

.csvReader:hover {
    background-image: url("../images/icon_btn_upload_over.svg");
}

.csvReader input {
    width: 56px;
    height: 56px;
    padding: 0;
    border: none;
    -moz-opacity: 0;
    opacity: 0;
    cursor: pointer;
}

.csvReader.disabled {
    pointer-events: none;
}

.csvReadAgreements {
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 0
}

/* Important */

.col-1 {
    width: 8.33% !important;
}

.col-2 {
    width: 16.66% !important;
}

.col-3 {
    width: 25% !important;
}

.col-4 {
    width: 33.33% !important;
}

.col-5 {
    width: 41.66% !important;
}

.col-6 {
    width: 50% !important;
}

.col-7 {
    width: 58.33% !important;
}

.col-8 {
    width: 66.66% !important;
}

.col-9 {
    width: 75% !important;
}

.col-10 {
    width: 83.33% !important;
}

.col-11 {
    width: 91.66% !important;
}

.col-12 {
    width: 100% !important;
}

.noPadding {
    padding: 0 !important;
}

.alignCenter {
    text-align: center !important;
}

.centeredOnPage {
    position: fixed;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.alignLeft {
    text-align: left !important;
}

.alignRight {
    text-align: right !important;
}

.floatLeft {
    float: left !important;
}

.floatRight {
    float: right !important;
}

.relativeP {
    position: relative !important;
}

.absoluteP {
    position: absolute !important;
}

.inBlock {
    display: inline-block !important;
}

.block {
    display: block !important
}

.none {
    display: none !important
}

.ofInit {
    overflow: initial !important
}

.excluded {
    background-color: #d6dcdf;
}

.excluded:hover {
    background-color: #d6dcdf !important;
}

.excluded div {
    opacity: 0.3;
    filter: alpha(opacity=30);
    /* For IE8 and earlier */
}

.excluded .opacity100 {
    opacity: 1 !important;
    filter: alpha(opacity=100);
    /* For IE8 and earlier */
}

.bolder {
    font-weight: bolder;
}

.underline {
    text-decoration: underline !important;
}

.marginTop {
    margin-top: 16px !important
}

.MT72 {
    margin-top: 72px !important
}

.mt8 {
    margin-top: 8px !important;
}

.clear {
    clear: both !important
}

.lastChild {
    flex-basis: 100% !important;
    margin-top: 28px;
}

.pad20 {
    padding: 20px !important
}

.borderTop {
    border-top: 1px solid var(--lightgray);
}

.displayFlex {
    display: flex
}

/* SPA */

.overTargetMargin .targetMargin,
.overTargetMargin {
    color: var(--customers) !important;
}

.underTargetMargin .targetMargin,
.underTargetMargin {
    color: #ff6256 !important;
}

.overTargetMargin .icon::before,
.underTargetMargin .icon::before {
    background-repeat: no-repeat;
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    transform: translate(-24px, -2px);
}

.overTargetMargin .icon::after,
.underTargetMargin .icon::after {
    background-repeat: no-repeat;
    position: absolute;
    content: none;
    height: 20px;
    width: 20px;
    transform: translate(8px, -2px);
}

.overTargetMargin .icon::before,
.overTargetMargin .icon::after {
    background-image: url("../images/icon_okay.svg");
}

.underTargetMargin .icon::before,
.underTargetMargin .icon::after {
    background-image: url("../images/icon_problem.svg");
}

.overTargetMargin .modified::before,
.underTargetMargin .modified::before {
    background-position-x: 0px, 20px;
    width: 40px;
    transform: translate(-44px, -2px);
}

.overTargetMargin .modified::after,
.underTargetMargin .modified::after {
    background-position-x: 0px, 20px;
    width: 40px;
    transform: translateX(8px, -2px);
}

.overTargetMargin .modified::before,
.overTargetMargin .modified::after {
    background-image: url("../images/icon_modified.svg"), url("../images/icon_okay.svg");
}

.underTargetMargin .modified::before,
.underTargetMargin .modified::after {
    background-image: url("../images/icon_modified.svg"), url("../images/icon_problem.svg");
}

.overTargetMargin p,
.underTargetMargin p {
    color: black;
}

@media (max-width:480px) {

    .overTargetMargin .icon::before,
    .underTargetMargin .icon::before {
        content: none;
    }

    .overTargetMargin .icon::after,
    .underTargetMargin .icon::after {
        content: '';
    }
}

/* Transition */

:hover {
    -webkit-transition: .2s ease-in;
    -moz-transition: .2s ease-in;
    -o-transition: .2s ease-in;
    transition: .2s ease-in;
}

/* Basic List */

.listPage .headerArea {
    position: relative;
    height: 196px;
}

.listPage .withTab {
    height: 134px;
}

.listPage .headerSticky {
    position: fixed;
    background-color: #f5f6fa;
    padding: 28px 0 0 0;
    z-index: 1;
}

.listPage .headerArea h2 {
    display: inline-block;
}

.listPage .headerArea p {
    color: var(--textcolor);
    float: right;
    padding-top: 12px;
}

.listPage .headerArea p span {
    color: var(--mediumgray);
}

.tabLists .innerBackground {
    background-color: var(--base);
    height: 122px;
}

.tabLists .headerTab,
.tabLists .headerBasicList,
.tabLists .innerBackground {
    z-index: 1;
    position: fixed;
}

.tabLists .headerTab {
    background-color: var(--base);
    z-index: 2;
}

.tabLists .headerBasicList {
    width: -webkit- calc(100% - 211px);
    width: -moz- calc(100% - 211px);
    width: calc(100% - 211px);
    margin-top: 68px;
    box-sizing: border-box;
}

.tabLists .basicList {
    padding-top: 130px;
}

.headerTab {
    margin: 0 0 8px 0;
    border-bottom: 2px solid var(--darkblue);
}

.headerTab .tab-link {
    background-color: var(--lightgray);
    border-radius: 4px 4px 0 0;
    color: var(--mediumgray);
    display: inline-block;
    font-size: 12px;
    font-weight: bolder;
    text-align: center;
    margin-right: 8px;
    padding: 20px 0;
    vertical-align: bottom;
    width: -webkit- calc(25% - 6px);
    width: -moz- calc(25% - 6px);
    width: calc(25% - 6px);
}

.headerTab .tab-link:hover {
    background-color: var(--mediumgray);
    color: var(--base);
}

.headerTab .tab-link:last-child {
    margin-right: 0px;
}

.headerTab .tab-link-active {
    background-color: var(--darkblue);
    font-weight: bold;
    color: var(--base);
    padding: 22px 0;
}

.headerTab .tab-link-active:hover {
    background-color: var(--darkblue);
    -webkit-transition: 0s ease-in;
    -moz-transition: 0s ease-in;
    -o-transition: 0s ease-in;
    transition: 0s ease-in;
}

.tab-link {
    cursor: pointer;
}

.headerBasicList {
    background-color: var(--lightgray);
    border-radius: 4px;
}

.headerBasicList div {
    display: inline-block;
    color: var(--textcolor);
    font-size: 12px;
    text-align: center;
    vertical-align: middle;
    font-weight: bolder;
    box-sizing: border-box;
    padding: 20px 0;
    overflow: hidden;
    text-overflow: ellipsis;
}

.basicList ul li {
    background-color: white;
    border-radius: 4px;
    margin-bottom: 8px;
    min-height: 56px;
}

.basicList ul li>div,
.basicList ul li a>div {
    display: inline-block;
    color: var(--textcolor);
    position: relative;
    vertical-align: middle;
    padding: 20px 4px;
    box-sizing: border-box;
    word-break: break-word;
}

.basicList ul li>div:first-child,
.basicList ul li a>div:first-child,
.headerBasicList div:first-child {
    padding-left: 36px;
}

.basicList ul li>div:last-child,
.basicList ul li a>div:last-child,
.headerBasicList div:last-child {
    padding-right: 36px;
}

.listDetail .basicList ul li div:first-child,
.listDetail .headerBasicList div:first-child,
.addList>div:first-child {
    padding-left: 20px;
}

.listDetail .basicList ul li div:last-child,
.listDetail .headerBasicList div:last-child,
.addList>div:last-child {
    padding-right: 20px;
}

.basicList ul li:hover,
.resultBySearch ul li:hover,
.basicList ul li.select,
.selectList ul li.selected {
    background-color: var(--darkblue);
}

.basicList ul li.select div,
.selectList ul li.selected p.bolder {
    color: var(--base);
}

.basicList ul li:hover div,
.resultBySearch ul li:hover div,
.basicList ul li:hover p {
    color: #78909c;
}

.basicList.unLinked ul li:hover div,
.basicList.unLinked ul li:hover p {
    color: black;
}

.resultBySearch ul li div {
    display: inline-block;
    color: var(--mediumgray);
    position: relative;
    vertical-align: middle;
    word-break: break-all;
}

.basicList ul li .bolder,
.basicList ul li .underline,
.resultBySearch ul li .bolder {
    color: var(--darkblue);
}

.basicList ul li:hover .bolder,
.basicList ul li:hover .underline,
.resultBySearch ul li:hover .bolder {
    color: white;
}

.basicList.unLinked ul li {
    cursor: default;
}

.basicList.unLinked ul li:hover {
    background-color: white;
}

.basicList.unLinked ul li:hover .bolder {
    color: var(--darkblue);
}

/* New SelectList List */

.selectList li.iconModified {
    background-image: url("../images/icon_modified.svg");
}

.selectList li.iconApproved {
    background-image: url("../images/icon_okay.svg");
    background-color: #f3fff6;
}

.selectList li.iconPending {
    background-image: url("../images/icon_pending.svg");
}

.selectList li.iconRejected {
    background-image: url("../images/icon_rejected.svg");
    background-color: #fff8f7;
}

.selectList li {
    border-radius: 4px;
    background: white;
    padding: 22px 10px 22px 10px;
    background-repeat: no-repeat;
    background-position: 12px center;
    margin-bottom: 8px;
    background-size: 26px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.selectList li:last-child {
    margin-top: 0;
}

.selectList p {
    text-align: left;
    padding: 0 0 0 36px;
    color: var(--mediumgray)
}

.selectList p:first-child {
    margin-bottom: 4px;
    color: var(--darkblue);
}

.selectList ul li.select p:first-child {
    color: var(--base);
}

.selectList .detailInfo {
    margin-top: 8px;
    padding: 12px;
    border-radius: 8px;
    background-color: #e6e9f0;
}

/* New Contract Input */

.customerSearch {
    position: relative;
}

/* Unknown */

.unknown {
    position: relative;
}

.unknown h3 {
    font-size: 12px;
    margin: 28px 0 16px 0;
    color: var(--textlight);
}

.unknown h3::before,
.unknown h3::after {
    content: '';
    border-bottom: 1px dotted var(--textlight);
    width: 40%;
    position: absolute;
    top: 6px;
}

.unknown h3::before {
    left: 0;
}

.unknown h3::after {
    right: 0;
}

.unknown .basicList ul li,
.unknown .basicList ul li:hover {
    background-color: var(--lightgray);
}

/* New Data Picker Component */

input[type="date"] {
    color: var(--darkblue);
    height: 40px;
    padding: 0 8px;
    -webkit-appearance: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1
}

input[type="date"]::after {
    content: '';
    background-image: url("../images/icon_btn_datepicker.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
}

/* New SPA Creation */

.basicPanel {
    background-color: white;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    flex-flow: wrap;
    width: 100%;
    padding: 0 8px;
    margin-bottom: 40px;
    box-sizing: border-box;
}

.basicPanel>div {
    flex: 1;
    border-right: 1px dotted var(--lightgray);
    padding: 40px 16px;
}

.basicPanel>div:last-child {
    border-right: none;
}

.basicPanel>div.no-border {
    border-right: none;
}

input.setMargin {
    width: -webkit- calc(100% - 36px);
    width: -moz- calc(100% - 36px);
    width: calc(100% - 36px);
    display: inline-block;
}

input.setMargin.no-percent {
    width: calc(100% - 18px);
}

input.setMargin+span::before {
    content: '%';
    margin-left: 4px;
}

.showContracts {
    margin: -6px 0 8px 0;
    background-color: white;
    border-radius: 4px;
}

.showContracts>.headerBasicList {
    border-radius: 4px 4px 0 0;
}

.showContracts .basicList ul li.selected {
    background-color: #2ac1d1 !important;
}

.showContracts .basicList ul li.selected .eligible {
    color: white !important;
}

.showContracts .basicList ul li.selected .eligible::before {
    content: url('../images/icon_quote_over.svg');
}

.showContracts .basicList ul li.selected div {
    color: white
}

.eligible::before {
    content: url('../images/icon_quote.svg');
    width: 20px;
    height: 20px;
    position: absolute;
    transform: translate(-20px, -2px);
}

.eligible {
    color: #2ac1d1 !important;
}

.quote::before {
    content: url('../images/icon_quote.svg');
    position: absolute;
    width: 20px;
    height: 20px;
    transform: translate(-12px, -8px);
}

.modalEditSPA .showContracts .basicList {
    padding: 0px;
}

.showContracts .basicList ul li {
    margin: 0 0 1px 0;
    border-bottom: 1px dotted var(--lightgray);
}

.showContracts .basicList ul li:last-child {
    border-bottom: none;
}

@media (max-width:768px) {
    .basicPanel {
        flex-direction: column;
        padding: 0;
    }

    .basicPanel>div {
        border-right: none;
        border-bottom: 1px dotted var(--lightgray);
    }

    .basicPanel>div:last-child {
        border-bottom: none;
    }
}

.excluded div.button {
    opacity: 1 !important;
}

.flex-line-break {
    flex-basis: 100% !important;
    padding: 20px 19px !important;
}

.settings-section-title {
    flex-basis: 100% !important;
    padding: 2px 17px !important;
    font-weight: bold;
    color: darkslategray;
    font-size: 15px;
}

/* SPA Creation Step 2 */

.splitPanel {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.singlePanel {
    flex: 1;
    margin-right: 20px;
}

.singlePanel:last-child {
    margin-right: 0;
}

.singlePanel.inverted {
    margin-top: 46px;
}

.singlePanel.inverted .headerBasicList {
    background-color: var(--darkblue);
}

.singlePanel.inverted .headerBasicList div {
    color: var(--lightgray)
}

.singlePanel .basicList {
    max-height: 420px;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 160px;
}

.singlePanel .basicList.unLinked {
    min-height: 12px;
}

@media (max-width:768px) {
    .splitPanel {
        flex-direction: column;
    }

    .singlePanel {
        margin: 0;
    }
}

/* New Add List */

.addList {
    border-radius: 4px;
    margin-bottom: 8px;
    background-color: var(--lightgray);
}

.addList>div {
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    padding: 8px 0;
}

.addList input,
.addList .selector {
    width: 95%;
}

/* New Buttons */

.moreBtn {
    width: 50%;
    background-color: var(--pointblue);
    padding: 16px 0;
    font-size: 14px;
    color: var(--base);
    cursor: pointer;
}

.moreBtn.aim {
    background-color: var(--btnnext);
    color: var(--base);
}

.moreBtn.aim.fitText {
    /* width: fit-content; */
    display: inline-block;
    width: auto;
    padding: 16px 10px;
}

.addContract {
    background-color: var(--pointblue);
    padding: 7px 20px;
    font-size: 14px;
    color: var(--base);
    vertical-align: top;
    margin-left: 24px;
    cursor: pointer;
}

.moreBtn:hover,
.addContract:hover {
    background-color: var(--darkblue);
    color: var(--base);
}

.btnRemove,
.btnSave,
.btnGPO,
.btnList {
    padding: 0;
    background-position: center;
    width: 12px;
    height: 12px;
    background-size: 12px;
    background-color: rgba(255, 255, 255, 0);
    margin: 0 6px;
    cursor: pointer;
}

.btnRemove {
    background-image: url("../images/icon_btn_remove.svg");
}

.btnRemove.exclude {
    background-image: url("../images/icon_btn_exclude.svg");
}

.btnRemove.include {
    background-image: url("../images/icon_btn_include.svg");
}

.btnSave {
    background-image: url("../images/icon_btn_check.svg");
}

.btnList {
    background-image: url("../images/icon_btn_list.svg");
}

.btnPrev,
.btnDelete,
.btnEdit,
.btnGPO,
.btnAdd,
.btnLogs,
.btnDownload,
.btnEstimate {
    width: 56px;
    height: 56px;
    padding: 0;
    margin: 0;
    background-position: center;
    cursor: pointer;
}

.btnPrev {
    background-image: url("../images/icon_btn_back.svg");
    background-size: 12px;
    background-color: white;
}

.btnDelete {
    background-image: url("../images/icon_btn_delete.svg");
    background-size: 26px;
    background-color: var(--btn);
}

.btnEdit {
    background-image: url("../images/icon_btn_edit.svg");
    background-size: 26px;
    background-color: var(--btn);
}

.btnGPO {
    background-image: url("../images/icon_btn_managegpo.svg");
    background-size: 26px;
    background-color: var(--btn);
}

.btnAdd {
    background-image: url("../images/icon_btn_add.svg");
    background-size: 20px;
    background-color: var(--agreements);
    width: 52px;
    height: 52px;
    margin-left: 8px;
}

.btnCopy {
    background-image: url("../images/icon_btn_copy.svg");
    background-size: 20px;
    background-color: var(--agreements);
    width: 52px;
    margin-left: 8px;
    background-position: center;
    background-size: 36px;
}

.btnLogs {
    background-image: url("../images/icon_log.svg");
    background-size: 30px;
    background-color: grey;
    width: 52px;
    height: 52px;
    margin-left: 8px;
}

.btnDownload {
    background-image: url("../images/icon_btn_download.svg");
    background-size: 30px;
    background-color: var(--btn);
}

.btnSmall {
    background-size: 20px;
    width: 40px;
    height: 40px;
}

.btnRecentlyOrdered {
    width: 100%;
    background-color: var(--pointblue);
    padding: 12px 10px;
    font-size: 14px;
    color: white;
    cursor: pointer;
    position: relative;
    text-align: center;
}

.btnRecentlyOrdered:hover {
    background-color: #0186c1;
}

.btnEstimate {
    background-image: url("../images/icon_btn_estimate.svg");
    background-size: 34px;
    ;
    background-color: var(--btn);
}

.btnDelete:hover,
.btnEdit:hover,
.btnGPO:hover,
.csvReader:hover,
.btnAdd:hover,
.btnDownload:hover,
.btnEstimate:hover {
    background-color: var(--pointblue);
}

.btnGPO:hover {
    background-image: url("../images/icon_btn_managegpo_over.svg");
}

.btnDelete:hover {
    background-image: url("../images/icon_btn_delete_over.svg");
}

.btnEdit:hover {
    background-image: url("../images/icon_btn_edit_over.svg");
}

.btnDownload:hover {
    background-image: url("../images/icon_btn_download_over.svg");
}

.btnEstimate:hover {
    background-image: url("../images/icon_btn_estimate_over.svg");
}

.btnPrev:hover {
    background-color: var(--lightgray);
    animation-name: btnPrev;
    animation-duration: 0.35s;
}

@keyframes btnPrev {
    0% {
        background-position-x: center;
    }

    10% {
        background-position-x: 26px;
    }

    70% {
        background-position-x: 18px;
    }

    100% {
        background-position-x: center;
    }
}

.btnApprove,
.btnReject,
.btnPending {
    width: 36px;
    height: 36px;
    margin: 0 2px;
    border-radius: 2px;
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: center;
    background-color: var(--lightgray);
    cursor: pointer;
}

.btnPending {
    background-image: url('../images/icon_pending.svg')
}

.btnApprove {
    background-image: url('../images/icon_okay.svg')
}

.btnReject {
    background-image: url('../images/icon_rejected.svg')
}

.btnApprove:hover {
    background-color: var(--customers);
    background-image: url('../images/icon_okay_over.svg')
}

.btnReject:hover {
    background-color: var(--contracts);
    background-image: url('../images/icon_rejected_over.svg')
}

.btnPending:hover {
    background-color: var(--agreements);
    background-image: url('../images/icon_pending_over.svg')
}

/* for responsive web */

@media (max-width:1024px) {
    .nav {
        width: 80px;
    }

    .nav .companyLogo {
        background-size: auto;
        background-image: url("../images/logo_dealmed.png");
    }

    .nav li,
    .nav .createspa {
        height: 100px;
        background-position: center;
        background-size: 44px;
    }

    .nav li:hover {
        background-size: 48px;
    }

    .nav ul li p,
    .nav .createspa p {
        display: none;
    }

    .topBar,
    .listPage .headerSticky,
    .tabLists .headerTab {
        width: -webkit- calc(100% - 160px);
        width: -moz- calc(100% - 160px);
        width: calc(100% - 160px);
    }

    .contentDetail,
    .listPage,
    .topBar {
        margin-left: 80px;
    }

    .topBar {
        padding: 15px 40px;
    }

    .listPage,
    .contentDetail {
        padding: 0px 40px;
    }

    .tabLists .headerBasicList {
        width: -webkit- calc(100% - 160px);
        width: -moz- calc(100% - 160px);
        width: calc(100% - 160px);
    }

    .basicList ul li>div:first-child,
    .basicList ul li a>div:first-child,
    .headerBasicList div:first-child {
        padding-left: 20px;
    }

    .basicList ul li>div:last-child,
    .basicList ul li a>div:last-child,
    .headerBasicList div:last-child {
        padding-right: 20px;
    }
}

@media (max-width:768px) {
    .topBar .profile {
        display: none;
    }

    .globalSearch {
        width: 100%;
    }

    .topBar {
        position: relative;
        margin-left: 0px;
        padding: 10px 12px;
        width: -webkit- calc(100% - 24px);
        width: -moz- calc(100% - 24px);
        width: calc(100% - 24px);
    }

    .nav .agreements,
    .nav .customers,
    .nav .vendors,
    .nav .items,
    .nav .contracts,
    .nav .settings,
    .nav .gpos {
        height: 52px;
        background-image: none;
        border-bottom: 1px solid #f5f6fa;
        display: table;
    }

    .nav ul.collapse {
        overflow-y: hidden;
        height: 0px;
        -webkit-transition: .15s ease-out;
        -moz-transition: .15s ease-out;
        -o-transition: .15s ease-out;
        transition: .15s ease-out;
    }

    .nav ul.expand {
        overflow-y: hidden;
        height: 371px;
        -webkit-transition: .15s ease-out;
        -moz-transition: .15s ease-out;
        -o-transition: .15s ease-out;
        transition: .15s ease-out;
    }

    .nav ul li p {
        display: table-cell;
        padding: 0;
        vertical-align: middle;
    }

    .nav li {
        min-height: 52px;
    }

    .nav .companyLogo {
        height: 60px;
        background-image: url("../images/logo_dealmed.png");
        background-size: 40%;
        display: inline-block;
        width: calc(100% - 120px);
        vertical-align: middle;
    }

    .nav .btnMenu {
        display: inline-block;
        height: 60px;
        width: 60px;
        padding: 0;
        background-color: rgb(255, 255, 255);
        border-radius: 0;
        background-image: url('../images/icon_btn_menu.png');
        background-repeat: no-repeat;
        background-position: center;
        vertical-align: middle;
    }

    @media (max-width:480px) {
        .nav .createspa {
            background-color: rgb(37, 37, 37)
        }
    }

    .nav .createspa {
        width: 60px;
        height: 60px;
        display: inline-block;
        vertical-align: middle;
        background-image: url('../images/icon_btn_create.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 24px;
    }

    .nav .createspa span::after,
    .nav .createspa span::before {
        content: none;
    }

    .tabLists .headerTab,
    .tabLists .innerBackground {
        position: relative;
        width: 100%;
        height: inherit;
    }

    .tabLists .headerBasicList {
        position: relative;
        margin-top: 8px;
        width: 100%;
    }

    .tabLists .basicList {
        padding-top: 8px;
    }

    .nav {
        position: relative;
        width: 100%;
        border-right: none;
    }

    .listPage,
    .contentDetail {
        margin: 0;
        padding: 24px 12px;
    }

    .infoBody,
    .listDetail,
    .listBody {
        flex: 1 100%;
        margin: 0 0 40px 0;
    }

    .listDetail.gpos {
        display: block;
        width: 100%;
        max-width: 100%;
        margin: 0 0 40px 0;
    }

    .infoDetail {
        margin: 0 0 20px 0;
        width: 100%;
        display: block;
        max-width: 100%;
    }

    .stickyArea .stickyContent,
    .listPage .headerSticky {
        width: 100%;
        padding: 20px 0 0 0;
        position: relative;
    }

    .floatPanel {
        display: block;
        padding: 20px;
        width: -webkit- calc(50% - 100px);
        width: -moz- calc(50% - 100px);
        width: calc(100% - 40px);
    }
}

@media (max-width:480px) {
    .listPage .headerArea {
        height: 100%;
    }

    .headerBasicList {
        display: none;
    }

    .resultBySearch ul li {
        padding: 16px 16px;
    }

    .basicList ul li .bolder,
    .resultBySearch ul li .bolder {
        font-size: 18px;
    }

    .divider {
        border-bottom: 1px dotted var(--lightgray);
        padding-bottom: 8px !important;
        margin-bottom: 8px;
    }

    .basicList ul li .underline {
        margin: 8px 4px;
    }

    .basicList ul li {
        padding: 12px 20px;
        min-height: initial;
    }

    .basicList ul li>div,
    .basicList ul li a>div,
    .resultBySearch ul li div {
        display: block;
        text-align: left;
        width: 100% !important;
        padding: 2px 0;
    }

    .basicList ul li>div:first-child,
    .basicList ul li a>div:first-child,
    .headerBasicList div:first-child,
    .listDetail .basicList ul li div:first-child,
    .listDetail .headerBasicList div:first-child {
        padding-left: 0;
    }

    .basicList ul li>div:last-child,
    .basicList ul li a>div:last-child,
    .headerBasicList div:last-child,
    .listDetail .basicList ul li div:last-child,
    .listDetail .headerBasicList div:last-child {
        padding-right: 0;
    }

    .mailtoList {
        padding-left: 0;
    }

    .moreBtn {
        width: 100%;
    }

    .itemSearch div {
        display: contents;
    }
}

#react-chat-slack-greeting {
    background-color: rgba(32, 145, 197, 0.356);
}

/* Loader */

.loader {
    width: 60px;
    height: 100px;
    background-image: url("../images/icon_pending.svg");
    background-repeat: no-repeat;
    background-size: 60px;
    animation: spin 1s ease-out infinite;
    margin: auto;
    background-position: center;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loader-sm {
    width: 20px;
    height: 30px;
    background-image: url("../images/icon_pending.svg");
    background-repeat: no-repeat;
    background-size: 30px;
    animation: spin 1s ease-out infinite;
    background-position: center;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loader-med {
    width: 50px;
    height: 80px;
    background-image: url("../images/icon_pending.svg");
    background-repeat: no-repeat;
    background-size: 50px;
    animation: spin 1s ease-out infinite;
    margin: auto;
    background-position: center;
}


@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Modal */

.modal {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
    left: 0;
    right: 0;
    top: 80px;
    bottom: 0;
}

.modalPopup,
.modalEditSPA {
    display: flex;
    position: relative;
    width: 320px;
    min-height: 234px;
    margin: auto;
    background-color: white;
    border-radius: 4px;
    vertical-align: middle;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    flex-direction: column;
    justify-content: space-between;
    animation-name: modal;
    animation-duration: 0.5s;
}

.modalEditSPA {
    width: auto;
    max-width: 1200px;
    margin: 0 auto;
    background-color: var(--base);
    border: none;
}

@media (max-width:1024px) {
    .modalEditSPA {
        margin: 0 20px;
    }
}

@media (max-width:480px) {
    .modal {
        top: 60px;
        overflow: auto;
    }

    .modalEditSPA {
        transform: translateY(0);
        top: 0;
        margin: 40px 12px;
    }

    .modalTitle {
        display: flex;
        flex-direction: column;
    }
}

@keyframes modal {
    0% {
        opacity: 0;
        transform: translateY(-20%)
    }

    100% {
        opacity: 1;
    }
}

.modalPopup p,
.modalEditSPA p {
    font-size: 18px;
    color: var(--darkblue);
    font-weight: bolder;
    padding: 8px 20px;
    background-repeat: no-repeat;
}

.modalEditSPA button.iconApproved,
.modalEditSPA button.iconPending,
.modalEditSPA button.iconRejected {
    width: 140px;
    font-size: 14px;
    border-radius: 24px;
    padding: 8px 0 8px 16px;
    color: var(--base);
    cursor: pointer;
    position: relative;
    text-align: left;
}

.modalEditSPA button.disabled {
    cursor: default;
    width: auto;
    padding: 8px 16px;
}

.modalEditSPA button.disabled::after {
    content: none !important;
}

.modalEditSPA button.iconApproved {
    background-color: var(--customers);
}

.modalEditSPA button.iconPending {
    background-color: var(--agreements);
}

.modalEditSPA button.iconRejected {
    background-color: var(--contracts);
}

.modalEditSPA button.iconApproved::before,
.modalEditSPA button.iconPending::before,
.modalEditSPA button.iconRejected::before {
    background-repeat: no-repeat;
    padding-left: 20px;
}

.modalEditSPA button.iconApproved::after,
.modalEditSPA button.iconPending::after,
.modalEditSPA button.iconRejected::after {
    content: url("../images/icon_arrow_down_over.svg");
    width: 16px;
    height: 16px;
    position: absolute;
    right: 12px;
}

.modalEditSPA .statusSelect button.iconApproved::after,
.modalEditSPA .statusSelect button.iconPending::after,
.modalEditSPA .statusSelect button.iconRejected::after {
    content: none;
}

.modalEditSPA button.iconApproved::before {
    content: 'Approved';
    background-image: url("../images/icon_okay_over.svg");
}

.modalEditSPA button.iconPending::before {
    content: 'Pending';
    background-image: url("../images/icon_pending_over.svg");
}

.modalEditSPA button.iconRejected::before {
    content: 'Rejected';
    background-image: url("../images/icon_rejected_over.svg");
}

.modalPopup .alert {
    padding-top: 40px;
}

.modalPopup .alert::before {
    content: '';
    width: 60px;
    height: 60px;
    background-image: url('../images/icon_problem.svg');
    background-repeat: no-repeat;
    background-size: 60px;
    display: block;
    margin: 0 auto;
}

.modalPopup .modalEstimateSPA {
    padding-top: 40px;
}

.modalPopup .modalEstimateSPA::before {
    content: '';
    width: 60px;
    height: 60px;
    background-image: url('../images/icon_estimate.svg');
    background-repeat: no-repeat;
    background-size: 60px;
    display: block;
    margin: 0 auto;
}

.modalPopup div,
.modalEditSPA div {
    vertical-align: bottom;
}

.modalContent>.headerBasicList {
    margin: 8px 20px 0 20px;
}

.modalContent>.basicList {
    margin: 0 20px 20px 20px;
    max-height: 400px;
    overflow-y: auto;
}

.modalPopup button,
.modalEditSPA .modalBtns button {
    width: 50%;
    background-color: var(--contracts);
    color: var(--base);
    font-size: 14px;
    font-weight: bolder;
    height: 56px;
    margin: 0;
    padding: 0;
}

.modalPopup button:last-child,
.modalEditSPA .modalBtns button:last-child {
    background-color: var(--btnnext);
}

.modalPopup button:hover,
.modalEditSPA .modalBtns button:hover {
    width: 50%;
    background-color: var(--darkblue);
    ;
    color: var(--base);
}

.modalPopup button:first-child,
.modalEditSPA .modalBtns button:first-child {
    border-radius: 0 0 0 4px;
    margin: 0;
}

.modalPopup button:last-child,
.modalEditSPA .modalBtns button:last-child {
    border-radius: 0 0 4px 0;
    margin: 0;
}

.modalTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
}

.modalTitle div button:first-child,
.modalTitle div button,
.modalTitle div button:last-child {
    margin: 0 4px;
}

.statusSelect {
    z-index: 100;
    position: absolute;
    border-radius: 16px;
    background-color: white;
    width: 160px;
    height: 78px;
    margin: 8px 0 0 -6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 8px 0;
    box-shadow: 0 0 12px 0 rgba(62, 108, 132, 0.2);
}

/* Date Picker */

.react-datepicker-wrapper,
.react-datepicker__input-container {
    display: inline-block;
    width: 100%;
}

.react-datepicker__input-container button {
    background-color: var(--base);
    border-radius: 4px;
    border: none;
    color: var(--darkblue);
    width: 100%;
    padding: 12px 28px 12px 8px;
    text-align: left;
    height: 40px;
    overflow: hidden;
}

.react-datepicker-popper {
    width: 100%;
    min-width: 280px;
    z-index: 1500;
}

.react-datepicker {
    font-family: sans-serif;
    font-size: 12px;
    background-color: white;
    color: var(--mediumgray);
    border: none;
    border-radius: 4px;
    display: inline-block;
    position: relative;
    width: 100%;
}

.react-datepicker__month-container {
    width: 100%;
}

.react-datepicker__triangle {
    left: 50%;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    border-bottom-color: var(--darkblue)
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-bottom: none;
    top: -1px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
    border-top-color: var(--darkblue)
}

.react-datepicker__month {
    background-color: white;
    margin: 0;
    padding: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.react-datepicker__header {
    text-align: center;
    background-color: var(--darkblue);
    border-bottom: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 10px 0 0 0;
    position: relative;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: var(--mediumgray);
    display: inline-block;
    width: 14.28%;
    line-height: 36px;
    text-align: center;
    margin: 0;
    font-size: 12px;
    font-weight: bolder;
}

.react-datepicker__day-name {
    line-height: 28px;
}

.react-datepicker__day:hover {
    border-radius: 0;
    background-color: var(--darkblue);
    color: var(--base);
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
    border-radius: 0;
    background-color: var(--pointblue);
    color: var(--base);
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--keyboard-selected:hover {
    background-color: var(--pointblue);
}

.react-datepicker__day--outside-month {
    color: var(--textlight);
}

.react-datepicker__current-month,
.react-datepicker-time__header {
    margin-top: 0;
    color: var(--base);
    font-weight: bolder;
    font-size: 14px;
}

.react-datepicker__month-dropdown-container--select,
.react-datepicker__year-dropdown-container--select {
    width: 50%;
    margin: 12px 0 0 0;
    position: relative;
    border-top: 1px solid #36444b;
    border-bottom: 1px solid #36444b;
}

.react-datepicker__month-dropdown-container--select select,
.react-datepicker__year-dropdown-container--select select {
    background-color: var(--darkblue);
    margin: 0;
    color: var(--textlight);
    font-size: 12px;
    text-align: center;
    text-align-last: center;
    height: 32px;
    cursor: pointer;
    width: 100%;
}

.react-datepicker__month-dropdown-container--select::after,
.react-datepicker__year-dropdown-container--select::after {
    content: '';
    transform: rotate(90deg) scale(0.5);
    position: absolute;
    top: 11px;
    right: 8px;
    border: 0.45rem solid transparent;
    border-left-color: var(--textlight);
    cursor: pointer;
    pointer-events: none;
}

.react-datepicker__navigation--previous {
    border-right-color: var(--textlight);
}

.react-datepicker__navigation--next {
    border-left-color: var(--textlight);
}

.react-datepicker__day-names {
    display: flex;
    justify-content: space-between;
}

.react-datepicker__input-container::after {
    content: '';
    background-color: var(--base);
    background-image: url("../images/icon_btn_datepicker.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 100%;
    position: absolute;
    right: 8px;
    pointer-events: none;
    top: 0;
}

.react-datepicker-wrapper .react-datepicker__input-container .selectCalendar {
    margin: 0;
    cursor: pointer;
}

/* Export */

#exportPDF {
    display: none;
}

#exportPDF table,
#exportPDF caption,
#exportPDF tr,
#exportPDF td {
    padding: 0;
    margin: 0;
    border-collapse: collapse
}

.exportHTML {
    position: relative;
    width: 100%;
    padding: 40px;
    box-sizing: border-box;
}

.exportHTML table {
    width: 100%;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
}

.exportHTML h2 {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.exportHTML th,
.exportHTML td {
    padding: 16px 4px;
    font-size: 13px;
}

.exportHTML tr {
    border-bottom: 1px dotted black;
}

.exportInfo {
    margin-bottom: 40px;
}

.exportInfo th {
    text-align: left;
}

.exportList tr.exportHeader {
    border-bottom: 2px solid black;
}

.exportList td {
    text-align: center;
}

.exportList td.alignLeft {
    text-align: left;
}

/* sortableTable */
.sortableHeader {
    user-select: none;
}

.sortableHeader:hover {
    cursor: pointer;
    background-color: rgb(224, 230, 233);
}

.sortableHeader.selected {
    background-color: rgb(220, 227, 230);
    color: #4a5356 !important;
}


.sort-icon {
    position: relative;
    left: 10px;
    height: 12px;
    top: 2px;
}

.sort-icon-wrapper {
    width: 13px;
    height: 13px;
    user-select: none;
}

.unknownItemsMarginTop {
    margin-top: 25px !important;
}

.updateDateHeader {
    padding: 0px !important;
    height: 0px;
}