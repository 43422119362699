table, caption, tr, td {
    padding:0;
    margin:0;
    border-collapse: collapse
}
.exportHTML {
    position: relative;
    width:100%;
    padding:40px;
    box-sizing: border-box;
}
.exportHTML table {
    width:100%;
    border-top:2px solid black;
    border-bottom:2px solid black;
}
.exportHTML h2 { 
    text-align:center;
    font-size:24px;
    font-weight:bold;
    margin-bottom:20px;
}
.exportHTML th, .exportHTML td {
    padding:16px 4px;
    font-size:13px;
}

.exportHTML tr {
    border-bottom:1px dotted black;
}

.exportInfo {
    margin-bottom:40px;
}

.exportInfo th {
    text-align:left;
}

.exportList tr.exportHeader {
    border-bottom:2px solid black;
}
.exportList td {
    text-align:center;
}
.exportList td.alignLeft {
    text-align:left;
}